<template>
  <el-card v-loading='loading' style='height: 340px'>
    <!--    <div slot="header" class="clearfix">-->
    <!--      <b style="font-size: 16px">我的权限 &nbsp;</b>-->
    <!--    </div>-->
    <el-tabs v-model='activeTab' class='text-center' @tab-click='handleTabClick'>
      <el-tab-pane label='基本权限' name='userinfo'>
        <div class='permissionsBox'>
          <b>当前角色：</b>
          <span style='font-size: 0.9em'>{{ userInfo.role_name || '' }} </span>
          <br />
          <b>所属部门：</b>
          <span style='font-size: 0.9em'>
            <span v-for='(dept, key) in userInfo.depts' :key='dept.dpt_id'>
              <el-tooltip :content='`${dept.name}(${dept.dpt_id})`' placement='bottom' effect='light'>
                <span style='margin-right: 5px'>{{ dept.name || '' }}
                  {{ key != userInfo.depts.length - 1 ? '/' : '' }}</span>
              </el-tooltip>
            </span>
          </span>
          <br />
          <b>所属领导：</b>
          <span v-for='leader in leaders' :key='`${leader.id}+${leader.dept_id}`'
                style='margin-right: 5px;font-size: 0.9em'>
            {{ leader.name || '' }} 『{{ leader.dept_name || '' }}』
              <span v-if="leader.is_leave == 'Y'" style='color: red'>（已离职）</span>
            </span>
        </div>
      </el-tab-pane>
      <el-tab-pane label='权限申请' name='application'>
        <div class='permissionsBox' v-if='options.length > 0' style='max-height: 320px'>
          <b>可选审批人（上级部门）：</b>
          <div style='max-height: 100px;overflow-y: scroll'>
            <el-checkbox-group v-model='selectLeaders'>
              <el-checkbox v-for='leader in leaders' :label='leader' :key='`${leader.id}+${leader.dept_id}`'> {{
                  leader.name
                }}『{{ leader.dept_name || '暂无部位' }} - {{ leader.position || '暂无职位' }}』
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <b>可申请角色：</b>
          <el-tooltip content='仅支持向直属上级申请其可授权的角色！！！！' placement='right' effect='light'>
            <span class='el-icon-info' style='color: #ff3176;font-size: 1em'></span>
          </el-tooltip>
          <div style='max-height: 200px;overflow-y: scroll;padding: 5px 0'>
            <el-checkbox-group v-model='selectRole' @change='handleSelectRole'>
              <el-checkbox v-for='(item, index) in options' :key='index' :label='item.id' style='display: block'>
                {{ item.display_name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>

        </div>
        <div v-else>暂无可申请的角色</div>

        <div style='display: block;width: 100%;text-align: center;'>
          <el-button round type='primary' size='mini' @click='submit()'
                     :disabled='loadingCommit||(selectRole.length===0||selectLeaders.length===0)'>
            角色申请（{{ selectRole.length }}）
          </el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label='申请记录' name='applicationHistory'>
        <div class='default-table'>
          <el-table :data='historyList' height='220'>
            <el-table-column align='left' label='申请内容' show-overflow-tooltip min-width='220' prop='rej_reason'>
              <template slot-scope='{row}'>
                <div>{{ row.apply_user_id === userInfo.id ? '你' : row.apply_user_name }}
                  于{{ $utils.parseTime(row.created_at, '{y}-{m}-{d} {h}:{i}') }}
                  向 {{ row.rec_user_name }}
                  申请『{{ row.role_names || '' }}』角色
                </div>
                <div class='sub'>
                  <span style='font-size: 0.9em;color: #F56C6C' v-if='row.rej_reason'>拒绝原因：{{ row.rej_reason || ''
                    }}</span>
                  <span style='font-size: 0.9em;color: #409EFF' v-if='row.remark'> 备注：{{ row.remark || '-' }}</span>
                </div>
              </template>
            </el-table-column>
            <!--            <el-table-column align='center' label='序号' type='index' width='60'></el-table-column>-->
            <!--            <el-table-column align='center' label='接收人' prop="rec_user_name" min-width='100'></el-table-column>-->
            <!--            <el-table-column align='center' label='角色' show-overflow-tooltip min-width='120' prop="role_names"></el-table-column>-->
            <!--            <el-table-column align='center' label='状态' prop="_status"  min-width='60'></el-table-column>-->
            <!--            <el-table-column align='center' label='拒绝原因/备注' show-overflow-tooltip  min-width='120' prop="rej_reason">-->
            <!--              <template slot-scope='{row}'>-->
            <!--                <span>{{row.rej_reason}}</span>-->
            <!--                <span>{{row.remark}}</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column align='center' label='备注' show-overflow-tooltip prop="remark"></el-table-column>-->
            <el-table-column
              width='140'
              fixed='right'
              label='操作' align='center'>
              <template slot-scope='{row}'>
                <el-link v-if='row.status===1' icon='el-icon-refresh-left' :disabled='loadingCommit'
                         @click='handleRevoke(row)' type='danger'>撤销
                </el-link>
                &nbsp;
                <el-link v-if='row.status===1' icon='el-icon-chat-line-square' :disabled='loadingCommit'
                         @click='handleNotify(row)'
                         type='warning'>通知
                </el-link>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :limit.sync='pagingData.page_size'
                      :page.sync='pagingData.current_page'
                      :total='pagingData.total'
                      small
                      layout='prev, pager, next'
                      @pagination='getHistory' />
        </div>
      </el-tab-pane>
      <el-tab-pane label='审批记录' name='forYouApply'>
        <div class='default-table'>
          <el-table :data='applyList' height='220'>
            <el-table-column align='left' label='申请内容' show-overflow-tooltip min-width='220' prop='rej_reason'>
              <template slot-scope='{row}'>
                <div>{{ row.apply_user_name }}
                  于{{ $utils.parseTime(row.created_at, '{y}-{m}-{d} {h}:{i}') }}
                  向 {{ row.rec_user_id === userInfo.id ? '你' : row.rec_user_name }}
                  申请『{{ row.role_names || '' }}』角色
                </div>
                <div class='sub'>
                  <span style='font-size: 0.9em;color: #F56C6C' v-if='row.rej_reason'>拒绝原因：{{ row.rej_reason || ''
                    }}</span>
                  <span style='font-size: 0.9em;color: #409EFF' v-if='row.remark'> 备注：{{ row.remark || '-' }}</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              width='140'
              fixed='right'
              label='操作' align='center'>
              <template slot-scope='{row}'>
                <el-link v-if='row.status===1' icon='el-icon-circle-check' :disabled='loadingCommit'
                         @click='handlePass(row)' type='primary'>同意
                </el-link>
                &nbsp;
                <el-link v-if='row.status===1' icon='el-icon-circle-close' :disabled='loadingCommit'
                         @click='handleRej(row)'
                         type='warning'>拒绝
                </el-link>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :limit.sync='pagingData.page_size'
                      :page.sync='pagingData.current_page'
                      :total='pagingData.total'
                      small
                      layout='prev, pager, next'
                      @pagination='getFoYouApply' />
        </div>
      </el-tab-pane>
    </el-tabs>


  </el-card>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'

export default {
  name: 'ApplyRole',
  components: { PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions', 'roles', 'userInfo'])
  },
  data() {
    return {
      loading: false,
      loadingCommit: false,
      //我的上级可申请的角色
      defaultProps: {
        id: 'id',
        children: 'children',
        label: 'display_name'
      },
      options: [],
      leaders: [], //我的上级领导
      formData: {
        selectRole: []
      },
      selectLeaders: [],
      selectRole: [],
      activeTab: 'userinfo',
      historyList: [],
      pagingData: {
        page_size: 5,
        current_page: 1,
        total: 0
      },
      applyList: []//向我申请的审批记录
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleTabClick(tab, event) {
      // console.log(tab, event)
      // console.log('event.type', event.type);
      switch (this.activeTab) {
        case 'applicationHistory':
          this.getHistory()
          break
        case 'forYouApply':
          this.getFoYouApply()
          break
        case 'application':
          this.getList()
          break
      }
    },
    // 获取可申请角色列表
    async getList() {
      this.loading = true
      let { leaders, roleList } = await this.$api.getMyApplyRoleList()
      let newRole = []
      if (roleList && roleList.length > 0) {
        roleList.forEach((item) => {
          if (item?.children.length > 0) {
            item.children.forEach((i) => {
              //去重
              let pos = newRole.findIndex(value => value.id === i.id)
              if (pos === -1) {
                //『${item.display_name}』
                i.display_name = `${i.display_name}`
                newRole.push(i)
              }
            })
          }
          delete item.children
          // 仅支持申请下级权限
          // newRole.push(item)
        })
      }
      this.options = newRole
      this.leaders = leaders
      setTimeout(() => {
        this.loading = false
      }, 50)
    },
    handleSelectRole(data) {
      this.formData.selectRole = this.options.map((item) => {
        if (data.find(i => {
          return i == item.id
        })) {
          return item
        } else return null
      }).filter(Boolean)
    },
    submit() {
      if (this.selectLeaders.length === 0) {
        this.$notify.warning('请先勾选审批人！！！')
        return false
      }
      if (this.selectRole.length === 0) {
        this.$notify.warning('请先勾选申请的角色！！！')
        return false
      }
      this.loadingCommit = true
      //提交申请
      // this.$notify.success(JSON.stringify(this.selectRole)) 『{{ leader.dept_name || '' }}』
      let leaderNames = this.selectLeaders.map((i) => `${i.name}『${i.dept_name || ''}』`)
      let roleNames = this.formData.selectRole.map((i) => i.display_name)
      let applyMsg = `此操作将向【${leaderNames.join('、')}】申请系统角色：【${roleNames.join('、')}】，确认提交申请？`
      this.$confirm(applyMsg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // console.log(this.selectRole,this.selectLeaders)
        this.handleCommitApply(this.selectRole)
        this.loadingCommit = false
      })
        .catch(() => {
          this.$notify.info('已取消')
          this.loadingCommit = false
        })
    },
    async handleCommitApply(selectRole) {
      //commitApply
      let data = await this.$api.postCommitApply(selectRole, this.selectLeaders)
      if (data) {
        this.msgSuccess('已提交申请，请耐心等待审批结果！！！')
      }
    },
    async getHistory() {
      let searchCondition = {}
      Object.assign(searchCondition, {
        page_size: this.pagingData.page_size || 10,
        current_page: this.pagingData.current_page || 1
      }, { apply_user_id: this.userInfo.id })

      let { list, pages } = await this.$api.getMyRoleApplyRecords(searchCondition)
      this.historyList = list || []
      this.pagingData.page_size = pages.page_size || 5
      this.pagingData.current_page = pages.current_page || 1
    },
    handleRevoke(row) {

      this.$confirm(`确定要撤销『角色：${row.role_names}』的申请吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.postRevokeApply(row.id)
        await this.getHistory()
      }).catch(() => {
        this.$notify.info('取消操作')
      })
    },
    handleNotify(row) {
      this.$confirm(`确定要再次通知审批『角色：${row.role_names}』的申请吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.notifyLeaderApply(row.id)
        await this.getHistory()

      }).catch(() => {
        this.$notify.info('取消操作')
      })
    },
    async getFoYouApply() {
      let searchCondition = {}
      Object.assign(searchCondition, {
        page_size: this.pagingData.page_size || 5,
        current_page: this.pagingData.current_page || 1
      }, { rec_user_id: this.userInfo.id })

      let { list, pages } = await this.$api.getMyRoleApplyRecords(searchCondition)
      this.applyList = list || []
      this.pagingData.page_size = pages.page_size || 5
      this.pagingData.current_page = pages.current_page || 1
    },
    async handleRej(row) {
      //拒绝申请
      this.$prompt('请输入拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      }).then(async ({ value }) => {
        this.loadingCommit = true
        await this.$api.handleRejRoleApply({ id: row.id, rej_reason: value })
        await this.getFoYouApply()
        this.loadingCommit = false
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    async handlePass(row) {
      //同意申请
      this.loadingCommit = true
      await this.$api.handlePassRoleApply({ id: row.id })
      await this.getFoYouApply()
      this.loadingCommit = false

    }

  },
  mounted() {
    this.getList()
    this.getHistory()
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-tabs__header {
  margin: 0 0 10px;
}

.permissionsBox {
  > b {
    display: inline-block;
    font-size: 16px;
    margin: 10px 0;
  }

  > span {
    font-size: 20px;
  }
}

.rej_reason {
  color: red;
  font-size: 0.9em;
}
</style>
